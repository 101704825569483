var Vietnam = {}
Vietnam.theDead = []

Vietnam.theDead[0]= {    
   'name':"James G Hood"
  ,'lat':15.990045
  ,'lng':107.830811
  ,'img':"/assets/imgs/s/Hood_James_Washington & Lee HS_1966.jpg"
    //also have Hood_James.jpg
  ,'imgalt':"James Hood at Washington &amp; Lee High School 1966"
  ,'imgname':"/assets/imgs/wall-names/wallName_hood.jpg"
  ,'audio':"/assets/media/James_G_Hood.mp3"
  ,'quote':"So all my hopes that you came back and married your highschool sweetheart suddenly went black and now I mourn."
  ,'infoWindowContent':"James G Hood was killed by an explosive device on November 12th, 1967.<br><br>He was 18 years old."
}
Vietnam.theDead[1]= {
   'name':"Bernard L Johnson II"
  ,'lat':9.149384
  ,'lng':105.413818
  ,'img': "/assets/imgs/s/BernardJohnson.jpg"
  ,'imgalt':"Bernie at Frederick College in 1965"
  ,'imgname':"/assets/imgs/wall-names/wallName_johnsonB.jpg"
  ,'audio':"/assets/media/Bernard_L_Johnson.mp3"
  ,'quote':"I think it's important to note that John frequently asked me to allow him to fly my routepac mission since I was married and he was not."
  ,'infoWindowContent':"Pilot Bernard L Johnson II crashed in Am Xugen Province on February 21st, 1972.<br><br>He was 28 years old."
}   
Vietnam.theDead[2]= {    
   'name':"Dwight P Bowles"
  ,'lat':21.365489
  ,'lng':104.567871
  ,'img':"/assets/imgs/s/Bowles_Dwight_P_Class_A.jpg"
            //also Bowles_Dwight_Beverly Manor HS_1946.jpg
  ,'imgalt':""
  ,'imgname':"/assets/imgs/wall-names/wallName_bowles.jpg"
  ,'audio':"/assets/media/Dwight_P_Bowles.mp3"
  ,'quote':"I will always remember him with 7 kids riding in his shiny car smiling as we ate ice creams he bought us on that hot day long ago..."
  ,'infoWindowContent':"Capt Dwight P Bowles was killed on November 3rd, 1965 during a bombing run.<br><br>His body was not recovered."
}   
Vietnam.theDead[3]= {    
   'name':"Gerald C Mull"
//  ,'lat':11.283131
//  ,'lng':105.974121
  ,'lat':11.315451
  ,'lng':105.935669
  ,'img':"/assets/imgs/s/GeraldMull.jpg"
  ,'imgalt':"Jerry Mull - James Madison Yearbook 1966"
  ,'imgname':"/assets/imgs/wall-names/wallName_mull.jpg"
  ,'audio':"/assets/media/Gerald_C_Mull.mp3"
//  ,'quote':"In spite of his terrible injury, he made his way to a second wounded soldier and was fatally shot in the neck after helping him."
  ,'quote':"His goal was to return to school and become a Dentist after serving his country."
  ,'infoWindowContent':"Gerald C Mull, a medic, was killed while helping his fellow soldiers in Tay Ninh Province on April 13th, 1968.<br><br>'Jerry' was 19 years old."
}
Vietnam.theDead[4]= {    
   'name':"Alan B Boffman"
  ,'lat':16.180057
  ,'lng':107.226563
  ,'img':"/assets/imgs/s/BOFFMAN_Alan_Booker_T_Washington HS_1963.jpg"
  ,'imgalt':"Alan in Booker T Washington HS (Norfolk) 1963 Yearbook"
  ,'imgname':"/assets/imgs/wall-names/wallName_boffman.jpg"
  ,'audio':"/assets/media/Alan_B_Boffman.mp3"
  ,'quote':"They died as they lived. Helping others and with honor."
  ,'infoWindowContent':"Alan B Boffman's helicopter was shot down over Laos on March 18th, 1971.<br><br>Alan was 24 years old."
}
Vietnam.theDead[5]= {    
   'name':"Everett A McPherson"
  ,'lat':20.053029
  ,'lng':105.424805
  ,'img':"/assets/imgs/s/McPHERSON_Everett_Granby HS_1959.jpg"
  ,'imgalt':"Everett at Granby HS in 1959"
  ,'imgname':"/assets/imgs/wall-names/wallName_mcpherson.jpg"
  ,'audio':"/assets/media/Everett_A_McPherson.mp3"
  ,'quote':"We were all so close growing up, and it wasn’t easy when the news came that Everett had been shot down and declared missing."
  ,'infoWindowContent':"Everett A McPherson's plane was shot down on March 18th, 1966 over Thanh Hoa Province in North Vietnam.<br><br>He was 23 years old."
}
Vietnam.theDead[6]= {    
   'name':"Fred V Cole"
  ,'lat':13.845747
  ,'lng':107.841797
  ,'img':"/assets/imgs/s/FredVCole.jpg"
    //also have FredVCole_group.jpg
  ,'imgalt':""
  ,'imgname':"/assets/imgs/wall-names/wallName_cole.jpg"
  ,'audio':"/assets/media/Fred_V_Cole.mp3"
  ,'quote':"He was so kind and gracious, and made me feel like I was going to make it."
  ,'infoWindowContent':"Fred V Cole was passenger on a helicopter that was shot down on May 12th, 1970.<br><br>At 39 years old, LTC Cole is the second oldest on this list."
}
Vietnam.theDead[7]= {    
   'name':"Jack P Dove Sr"
  ,'lat':17.2000  //official coords from military. Don't adjust
  ,'lng':106.12000
  ,'img':"/assets/imgs/s/Dove_Jack_Paris.jpg"
    //also Dove_Jack_Paris_standing.jpg
  ,'imgalt':""
  ,'imgname':"/assets/imgs/wall-names/wallName_dove.jpg"
  ,'audio':"/assets/media/Jack_P_Dove.mp3"
  ,'quote':"He was shot down in July 1967... only needing 7 more missions to come home."
  ,'infoWindowContent':"Jack P Dove Sr died during a night reconnaissance mission over Quang Binh Province, North Vietnam.<br><br>He was 26 years old."
}
Vietnam.theDead[8]= {    
   'name':"Robert M Wade"
  ,'lat':15.624938
  ,'lng':108.479004
  ,'img':"/assets/imgs/s/RobertWade.jpg"
  ,'imgalt':""
  ,'imgname':"/assets/imgs/wall-names/wallName_wade.jpg"
  ,'audio':"/assets/media/Robert_M_Wade.mp3"
  ,'quote':"You are the whisper in the night that says I'll keep you safe... no one will ever harm you... and that tells daily how proud you are of me."
  ,'infoWindowContent':"Robert M Wade died in Quang Tin Province on October, 12th, 1967.<br><br>Robert would have turned 30 one month later."
}
Vietnam.theDead[9]= {    
   'name':"Fred V Lee"
  ,'lat':16.990800
  ,'lng':107.006836
  ,'img':"/assets/imgs/s/Fred_Vincent_Lee.jpg"
  ,'imgalt':""
  ,'imgname':"/assets/imgs/wall-names/wallName_lee.jpg"
  ,'audio':"/assets/media/Fred_V_Lee.mp3"
  ,'quote':"I was your Casualty Escort. I met you at Dover AFB and escorted you back to VA for your funeral."
  ,'infoWindowContent':"Fred V Lee died in combat to an explosive device on June 1st, 1967 in Quang Tri Provivce.<br><br>He had just turned 22 years old."
}
Vietnam.theDead[10]= {    
   'name':"Talmadge H Alphin Jr"
  ,'lat':15.588317
  ,'lng':107.446289
  ,'img':"/assets/imgs/s/Alphin_Talmadge_H_DOB_1943.jpg"
  ,'imgalt':""
  ,'imgname':"/assets/imgs/wall-names/wallName_alphin.jpg"
  ,'audio':"/assets/media/Talmadge_H_Alphin.mp3"
  ,'quote':"I wish we all kept in touch."
  ,'infoWindowContent':"Talmadge H Alphin Jr was a ground casuality on August 23rd, 1968.<br><br>He was 25 years old."
}
Vietnam.theDead[11]= {    
   'name':"Ronald E Ange"
 // ,'lat':11.250807
 // ,'lng':106.457520
 ,'lat':10.959731
  ,'lng':106.528931
  ,'img':"/assets/imgs/s/Ronnie_Ange.jpg"
  ,'imgalt':""
  ,'imgname':"/assets/imgs/wall-names/wallName_ange.jpg"
  ,'audio':"/assets/media/Ronald_E_Ange.mp3"
  ,'quote':"At your funeral, I cried like a little baby. The next day (on your 19th Birthday) I boarded that plane to the other side of the world."
  ,'infoWindowContent':"Ronald E Ange's died when his unit was ambushed in the Ho Bo Woods.<br><br>He was 18 years old."
}
Vietnam.theDead[12]= {    
   'name':"Dallas A Driver"
  //,'lat':11.250807
  //,'lng':106.457520
  ,'lat':11.573880
  ,'lng':106.342163
  ,'img':"/assets/imgs/s/Dallas_Driver.jpg"
  ,'imgalt':""
  ,'imgname':"/assets/imgs/wall-names/wallName_driver.jpg"
  ,'audio':"/assets/media/Dallas_Driver.mp3"
  ,'quote':"Dallas was my first love... We got married on May 30, 1969 and we had 2 1/2 wonderful weeks together before he went to Vietnam."
  ,'infoWindowContent':"Dallas A Driver died when his helicopter crashed during an extraction near the shores of the Song Dong Nai River.<br><br>He was 22 years old."
}
Vietnam.theDead[13]= {    
   'name':"Cecil C Perkins Jr"
  ,'lat':15.884404
  ,'lng':108.281250
  ,'img':"/assets/imgs/s/Perkins_Cecil.jpg"
  ,'imgalt':""
  ,'imgname':"/assets/imgs/wall-names/wallName_perkins.jpg"
  ,'audio':"/assets/media/Cecil_C_Perkins.mp3"
  ,'quote':"They transmitted garbled words that included 'fire' and the aircraft disappeared from the radar screen."
  ,'infoWindowContent':"Cecil C Perkins Jr's aircraft was lost while flying northeast of Da Nang on December 14th, 1971.<br><br>He was 25 years old."
}
Vietnam.theDead[14] = {
     'name':"Lorenzo R Johnson"
    ,'lat':11.283131
    ,'lng':106.902466
    ,'img': "/assets/imgs/s/LorenzoJohnson.jpg"
    ,'imgalt':""
    ,'imgname':"/assets/imgs/wall-names/wallName_johnsonL.jpg"
    ,'audio':"/assets/media/Lorenzo_Johnson.mp3"
    ,'quote':"You told her you were not coming back and you had seen it in a vision, that you made peace with it."
    ,'infoWindowContent':"Lorenzo R Johnson died to an explosive device during combat in Bing Duong Province.<br><br>He was 20 years old."
}
Vietnam.theDead[15]= {    
   'name':"Humbert Roque Versace"
  ,'lat':9.478524
  ,'lng':104.930420
  ,'img':"assets/imgs/s/Versace_Humbert_R_DOB_1937.jpg"
  ,'imgalt':""
  ,'imgname':"/assets/imgs/wall-names/wallName_versace.jpg"
  ,'audio':"/assets/media/Capt_Humbert_Roque_Versace.mp3"
  ,'quote':'The last time that any of his fellow prisoners heard from him, he was singing "God Bless America" at the top of his voice from his isolation box.'
  ,'infoWindowContent':"Capt Humbert Roque Versace, of Norfolk, was captured on October 29th, 1963, and executed on September 25th, 1965.<br><br><em>He was 28 years old.</em>"
}
Vietnam.theDead[16]= {    
   'name':"Richard L Lassiter"
  ,'lat':14.782518
  ,'lng':108.852539
  ,'img':"/assets/imgs/s/PFC_Richard_Leon_Lassiter.jpg"
  ,'imgalt':""
  ,'imgname':"/assets/imgs/wall-names/wallName_lassiter.jpg"
  ,'audio':"/assets/media/Richard_L_Lassiter.mp3"
  ,'quote':"He was walking the point when we were ambushed."
  ,'infoWindowContent':"Richard L Lassiter died in combat in Quang Naai Province on May 5th, 1969.<br><br>He was 20 years old."
}
Vietnam.theDead[17]= {    
   'name':"Clayton D Wright"
  ,'lat':10.862641
  ,'lng':107.687988
  ,'img':"/assets/imgs/s/SPEC4_Davis_Wright.jpg"
    //also have Clayton_Wright_marker.jpg, but it's not of him, but of his memorial
  ,'imgalt':""
  ,'imgname':"/assets/imgs/wall-names/wallName_wright.jpg"
  ,'audio':"/assets/media/Clayton_D_Wright.mp3"
  ,'quote':"He was the FUN Uncle."
  ,'infoWindowContent':"Clayton D Wright died in Long An Province, South Vietnam, on July 14th, 1968.<br><br>He was 24 years old."
}
Vietnam.theDead[18]= {    
   'name':"Ronald S Lowe"
  ,'lat':13.653660
  ,'lng':108.380127
  ,'img':"/assets/imgs/s/Lowe_Ronald_S_DOB_1949_VA.jpg"
    //also have Ronald_Lowe_group_drawing.jpg
    //also have Ronald_Lowe_with_gun.jpg
  ,'imgalt':"Ronald Lowe holding a copy of the book Catch-22"
  ,'imgname':"/assets/imgs/wall-names/wallName_lowe.jpg"
  ,'audio':"/assets/media/Ronald_S_Lowe.mp3"
  ,'quote':"I never knew you and have felt an emptiness all of my life."
  ,'infoWindowContent':"Ronald S Lowe was one of five photographers killed during an operation on May 9th, 1970.<br><br>He was 21 years old."
}
Vietnam.theDead[19]= {    
   'name':"Orange Gooding"
  ,'lat':12.765933
  ,'lng':108.896484
  ,'img':"/assets/imgs/s/SFC_Orange_Gooding_Ft_Polk_1966.jpg"
  ,'imgalt':""
  ,'imgname':"/assets/imgs/wall-names/wallName_gooding.jpg"
  ,'audio':"/assets/media/Orange_Gooding.mp3"
  ,'quote':"I went to the Wall, I found Orange's name and did the only thing I knew to do. I cried and 'gave him twenty'"
  ,'infoWindowContent':"Orange Gooding died on March 7th, 1969 in Khanh Hoa Province.<br><br>SFC Gooding was the only man in this list to survive to 40 years old."
}